import React, { useState } from 'react';
import firstLoot from './assets/easterEgg/loot_1_before_hover.png';
import firstLootHover from './assets/easterEgg/loot_1_hover.png';
import './App.css'; // Import your component-specific CSS file

const Test = ({ gemCount, setGemCount }) => {
    const [isCollected, setIsCollected] = useState(false);
  
    const handleFirstLootClick = () => {
        if (!isCollected) {
          setGemCount(gemCount + 351);
          setIsCollected(true);
        }
      };

return (
    <div className='firstLootContainer'>
        {!isCollected ? (
          <img 
          src={firstLoot} 
          alt='351gems' 
          className='firstLootGems' 
          onClick={handleFirstLootClick}/>
          
        ) : (
          <div>
            <p className="gemCounter">{gemCount}</p>
          </div>
        )}
        <img 
            src={firstLootHover} 
            alt='351gems' 
            className='firstLootGems2'
            onClick={handleFirstLootClick}
            />
      </div>
    );
};

export default Test;