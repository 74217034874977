import React, { useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './NewPage.css';
import Modal from 'react-modal';

import FortwenyVideo from './IntroVideo';
import FortwenyVideoSource from './assets/videos/420load.mp4';

import Book_of_Spells_1 from './assets/second_page_images/Book_of_Spells.png';
import Book_of_Everything from './assets/second_page_images/Book_of_Everything.png';
import Book_of_Fighting from './assets/second_page_images/Book_of_Fighting.png';
import Book_of_Heroes from './assets/second_page_images/Book_of_Heroes.png';
import Book_of_Spells_2 from './assets/second_page_images/Book_of_Spells.png';

import heheFace from './assets/second_page_images/hehe.png';

function NewPage() {
    const navigate = useNavigate();

    const [showContent, setShowContent] = useState(false);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([false, false, false, false, false]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentQuestionText, setCurrentQuestionText] = useState('');
    const [userAnswer, setUserAnswer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const questions = [
        { image: Book_of_Spells_1, alt: 'questionOne', question: 'When is HeHeHaHa birthday? MM/DD', answer: '03/04', className: 'questionOne' },
        { image: Book_of_Everything, alt: 'questionTwo', question: 'How many gems did it take to get here?', answer: '420', className: 'questionTwo' },
        { image: Book_of_Fighting, alt: 'questionThree', question: 'Whats my favorite number?', answer: '69', className: 'questionThree' },
        { image: Book_of_Heroes, alt: 'questionFour', question: 'Whats a four letter word you can call me?', answer: 'king', className: 'questionFour' },
        { image: Book_of_Spells_2, alt: 'questionFive', question: 'Medical bills will cost how much $HAHA? 0.000', answer: '0.479', className: 'questionFive' }
    ];

    const handleClick = (index) => {
        if (index <= currentQuestion) {
            setCurrentQuestionText(questions[index].question);
            setIsModalOpen(true);
            setErrorMessage(''); // Clear any previous error message
        }
    };

    const handleSubmit = () => {
        const isCorrect = userAnswer.trim().toLowerCase() === questions[currentQuestion].answer.trim().toLowerCase();

        if (isCorrect) {
            const newAnswers = [...answers];
            newAnswers[currentQuestion] = true;
            setAnswers(newAnswers);
            setCurrentQuestion(currentQuestion + 1);
            setIsModalOpen(false);
            setUserAnswer('');
        } else {
            setErrorMessage('HEHEHAHAHA that is incorrect.');
        }
    };

    const handleVideoEnd = () => {
        setShowContent(true);
    };

    const redirectToHeHeHaPage = () => {
        if (answers.every(answer => answer)) {
            navigate("/heheha"); 
        } else {
            alert('HEHEHAHA not so fast. Only the wise gets through!');
        }
    };

    const customStyles = {
        content: {
            top: '70%',
            left: '51%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%', // Custom width
            height: '50%', // Custom height
            padding: '20px', // Custom padding
            backgroundColor: '#fff', // Custom background color
            borderRadius: '10px', // Custom border radius
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' // Custom box shadow
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)' // Custom overlay background
        }
    };

    return (
        
        <div className="new-page-container">
            {!showContent && <FortwenyVideo videoSource={FortwenyVideoSource} onVideoEnd={handleVideoEnd} />}
            {showContent && (
                <div className='New-Page-Main'>
                    
                    <div className='hehe2nd' onClick={redirectToHeHeHaPage}>
                        <img 
                            src={heheFace}
                            alt='heheFace'
                             />
                    </div>
                    {questions.map((q, index) => (
                        <div key={index} className={q.className} onClick={() => handleClick(index)}>
                            <img 
                                src={q.image} 
                                alt={q.alt} 
                                className={`Book_of_Spells_${index + 1}`}
                            />
                        </div>
                    ))}
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={() => setIsModalOpen(false)}
                        contentLabel="Question Modal"
                        style={customStyles}
                    >
                        <h2>{currentQuestionText}</h2>
                        <input 
                            type="text" 
                            value={userAnswer}
                            onChange={(e) => setUserAnswer(e.target.value)}
                        />
                        <button onClick={handleSubmit}>Submit</button>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    </Modal>
                </div>
                
            )};
            
        </div>
    );
}

export default NewPage;

