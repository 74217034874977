import React, { useRef, useEffect } from 'react';
import './IntroVideo.css';


const IntroVideo = ({ videoSource, onVideoEnd }) => {
    const videoRef = useRef();

    useEffect(() => {
        const video = videoRef.current;
        video.addEventListener('ended', onVideoEnd);

        return () => {
            video.removeEventListener('ended', onVideoEnd);
        };
    }, [onVideoEnd]);

    return (
        <div className="intro-video-container">
            <video ref={videoRef} autoPlay muted>
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default IntroVideo;
