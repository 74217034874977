import React, { useState } from 'react';
import Draggable from 'react-draggable';
import './Popup.css';
import redXButton from './assets/images/redXButton.png';
import insideContent from './assets/images/popUpInsideTest.png';
import secondLootHover from './assets/easterEgg/chest_of_gems_hover.png';
import secondLoot from './assets/easterEgg/chest_of_gems_before_hover.png';


const Popup = ({ onClose, gemCount, setGemCount }) => {
  const [isCollected, setIsCollected] = useState(false);

  const handleSecondLootClick = () => {
    if (!isCollected) {
      setGemCount(gemCount + 69);
      setIsCollected(true);
    }
  };

  return (
    <Draggable>
      <div className="popup-container">
        <div className="popup-header">
          <button className="close-button" onClick={onClose}>
            <img src={redXButton} alt='Close'/>
          </button>
        </div>
        <div className="popup-content">
          <img src={insideContent} alt='Popup Content' className='popup-image'/>
          <div className='secondLootContainer'>
            {!isCollected ? (
              <img 
                src={secondLoot} 
                alt='69gems' 
                className='secondLootGems' 
                onClick={handleSecondLootClick}/>
          
            ) : (
              <div>
                <p className="gemCounter">{gemCount}</p>
              </div>
            )}
            <img 
              src={secondLootHover} 
              alt='69gems' 
              className='secondLootGems2'
              onClick={handleSecondLootClick}
              />
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Popup;