import React from 'react';
import './heheha.css';

import hehe1 from './assets/final_page_images/heheha1.png';
import hehe2 from './assets/final_page_images/heheha2.png';
import hehe3 from './assets/final_page_images/heheha3.png';
import hehe4 from './assets/final_page_images/heheha4.png';
import hehe5 from './assets/final_page_images/heheha5.png';
import hehe6 from './assets/final_page_images/heheha6.png';
import hehe7 from './assets/final_page_images/heheha7.png';
import hehe8 from './assets/final_page_images/heheha8.png';
import hehe9 from './assets/final_page_images/heheha9.png';
import hehe10 from './assets/final_page_images/heheha10.png';
import hehe11 from './assets/final_page_images/heheha11.png';
import hehe12 from './assets/final_page_images/heheha12.png';
import hehe13 from './assets/final_page_images/heheha13.png';
import hehe14 from './assets/final_page_images/heheha14.png';
import hehe15 from './assets/final_page_images/heheha15.png';
import hehe16 from './assets/final_page_images/heheha16.png';
import hehe17 from './assets/final_page_images/heheha17.png';
import hehe18 from './assets/final_page_images/heheha18.png';
import hehe19 from './assets/final_page_images/heheha19.png';
import hehe20 from './assets/final_page_images/heheha20.png';
import hehe21 from './assets/final_page_images/heheha21.png';
import hehe22 from './assets/final_page_images/heheha22.png';
import hehe23 from './assets/final_page_images/heheha23.png';
import hehe24 from './assets/final_page_images/heheha24.png';
import hehe25 from './assets/final_page_images/heheha25.png';
import hehe26 from './assets/final_page_images/heheha26.png';
import hehe27 from './assets/final_page_images/heheha27.png';
import hehe28 from './assets/final_page_images/heheha28.png';

import headerImage from './assets/final_page_images/header.png';
import footerImage from './assets/final_page_images/footer.png';

import hahaha from './assets/final_page_images/hehehehehe.png';

function hehehaPage() {

    

    return (
        <div className="heheha-page-container">
            <div className="scrollable-content">
                
                <div className="scrolling-text-bar">
                    <div className="scrolling-text">
                        THIS JUST IN! --- $HAHA NOW CROSSES 100 BILLION MARKET CAP. ELON'S LIVE RESPONSE: "HEHEHAHAHA I JUST BOUGHT NEW ROCKET FUEL WITH $HAHA" TRUMP'S LIVE RESPONSE: "GRAB EM BY THE HAHA"
                    </div>
                </div>
                <div className='divHeader'>
                    <img src={headerImage} alt='headerImage' className='headerImage'/>
                </div>
                <div className="image-grid">
                    <img src={hehe1} alt="heheha1" className="grid-image" />
                    <img src={hehe2} alt="heheha2" className="grid-image" />
                    <img src={hehe3} alt="heheha3" className="grid-image" />
                    <img src={hehe4} alt="heheha4" className="grid-image" />
                    <img src={hehe5} alt="heheha5" className="grid-image" />
                    <img src={hehe6} alt="heheha6" className="grid-image" />
                    <img src={hehe7} alt="heheha7" className="grid-image" />
                    <img src={hehe8} alt="heheha8" className="grid-image" />
                    <img src={hehe9} alt="heheha" className="grid-image" />
                    <img src={hehe10} alt="heheha" className="grid-image" />
                    <img src={hehe11} alt="heheha" className="grid-image" />
                    <img src={hehe12} alt="heheha" className="grid-image" />
                    <img src={hehe13} alt="heheha" className="grid-image" />
                    <img src={hehe14} alt="heheha" className="grid-image" />
                    <img src={hehe15} alt="heheha" className="grid-image" />
                    <img src={hehe16} alt="heheha" className="grid-image" />
                    <img src={hehe17} alt="heheha" className="grid-image" />
                    <img src={hehe18} alt="heheha" className="grid-image" />
                    <img src={hehe19} alt="heheha" className="grid-image" />
                    <img src={hehe20} alt="heheha" className="grid-image" />
                    <img src={hehe21} alt="heheha" className="grid-image" />
                    <img src={hehe22} alt="heheha" className="grid-image" />
                    <img src={hehe23} alt="heheha" className="grid-image" />
                    <img src={hehe24} alt="heheha" className="grid-image" />
                    <img src={hehe25} alt="heheha" className="grid-image" />
                    <img src={hehe26} alt="heheha" className="grid-image" />
                    <img src={hehe27} alt="heheha" className="grid-image" />
                    <img src={hehe28} alt="heheha" className="grid-image" />
                </div>
                <div className='divFooter'>
                    <img src={footerImage} alt='footerImage' className='footerImage'/>
                </div>
            </div>
        </div>
    );
}

export default hehehaPage;