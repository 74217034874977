import React from 'react';
import Draggable from 'react-draggable';
import './PopupParty.css';
import redXButton from './assets/images/redXButton.png';
import partyVideo from './assets/videos/partyVideo2.mp4';

const PopupBattle = ({ onClose }) => {

  return (
    <Draggable>
      <div className="popup-containerParty">
        <div className="popup-headerParty">
          
          <button className="close-button" onClick={onClose}>
            <img src={redXButton} alt='Close'/>
          </button>
        </div>
        
        <video className='popup-video' autoPlay>
            <source src={partyVideo} type='video/mp4' />
            Your browser does not support the video tag
        </video>
      </div>
    </Draggable>
  );
};

export default PopupBattle;